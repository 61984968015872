import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import {
  ArrowReturnRight,
  BookHalf,
  Compass,
  Cone,
  GearWide,
} from "react-bootstrap-icons"

export default function InfoSection() {
  const sections = [
    {
      name: "Kartsport",
      desc: "Zu Beginn der 80er Jahre wurde bei den MSF eine Gruppe für Kartinteressierte Jugendliche ins Leben gerufen. Alljährlich findet ein Jugendkartslalom in Plettenberg statt.",
      icon: <Cone />,
      link: "../verein/kartsport",
    },
    {
      name: "Historie",
      desc: "Die MotorSportFreunde Plettenberg blicken auf mehr als 80 Jahre Vereinsleben zurück. Sehen Sie sich unsere Geschichte an.",
      icon: <BookHalf />,
      link: "../verein/historie",
    },
    {
      name: "Oldtimer",
      desc: 'Seit vielen Jahren fühlen wir uns den historischen Fahrzeugen verbunden. Aus diesem Grund richten wir verschiedene Veranstaltungen, wie das "Oldtimer-Frühschoppen" aus.',
      icon: <GearWide />,
      link: "../verein/oldtimer",
    },
    {
      name: "Touristik",
      desc: "In den 60er Jahren waren die MSF sehr stark im Tourensport vertreten. Gern erinnern sich die Mitglieder noch an die Sternfahrten nach Johannesberg, Zotzenbach, Emden, Gefrees, Landhadeln und 1963 sogar nach Gent in Belgien zurück.",
      icon: <Compass />,
      link: "../verein/touristik",
    },
  ]

  return (
    <Row className="mt-md-5" xs={1} md={2}>
      {sections.map((section, index) => (
        <Col className="my-5" key={index}>
          <div className="d-flex align-items-center mb-3">
            <div className="d-flex align-items-center px-3 py-2 mr-3 bg-primary text-white rounded display-4">
              {section.icon}
            </div>
            <p className="h3 font-weight-bold mb-0 pb-0">{section.name}</p>
          </div>
          <p className="lh-text text-dark mt-0">{section.desc}</p>
          <Link to={section.link} className="text-decoration-none">
            <Button
              variant="link"
              size={"sm"}
              className="d-flex align-items-center text-decoration-none px-0"
            >
              <ArrowReturnRight className="mr-2" /> Erfahren Sie mehr...
            </Button>
          </Link>
        </Col>
      ))}
    </Row>
  )
}
