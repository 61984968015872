import React from "react"
import Container from "react-bootstrap/Container"
import InfoSection from "../../components/InfoSection"
import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import Seo from "../../components/Seo"

export const Head = () => {
  return <Seo customTitle="Verein" />
}

export default function verein() {
  return (
    <Layout>
      <Container className="my-5">
        <PageTitle title="Verein" />
        <p className="lh-text mb-5 lead font-weight-normal">
          Seit 1930 engagieren sich die MotorSportFreunde Plettenberg in den
          Bereichen Kartslalom, Oldtimer und Touristik. Alljährlich findet ein
          Vorlauf zur deutschen Meisterschaft im Jugendkartslalom in Plettenberg
          statt. Zudem werden regelmäßig Veranstaltungen rund um Oldtimer und
          der dazu gehörigen Oldtimerrallye organisiert.
        </p>
        <hr className="mb-5 invisible" />
        <InfoSection />
      </Container>
    </Layout>
  )
}
